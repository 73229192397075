import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import LoginHeader from "../../SharedComponents/LoginHeader";
import { saveSchemeType } from '../../actions/EmployeeLoginAction';
import { saveAuthenticationFlag } from '../../actions/TwoFactorAuthenticationAction';
import "../../css/EmpLogin.css";
import ErAgentsOtherInd from "./LoginTypes/ErAgentsOtherInd";
import OtherORSO from "./LoginTypes/OtherORSO";
import S800andORSO from "./LoginTypes/S800andORSO";
import { DD_MM_YYYY_HH_mm_ss, asiaHongKong, enGB } from '../../translations/common/commonConstants';
import moment from 'moment';
import SmartSimple from "./LoginTypes/SmartSimple";

const LoginComponent = (props) => {
  const { t } = useTranslation("common");
  const currentDateHk = new Date();
  const hkTimeString = currentDateHk.toLocaleString(enGB, { timeZone: asiaHongKong });
  const hkTime = moment(hkTimeString, DD_MM_YYYY_HH_mm_ss, true);
  // eslint-disable-next-line no-undef
  const cobrandTerminationDate = moment(process.env.REACT_APP_COBRAND_TERMINATION_DATE, DD_MM_YYYY_HH_mm_ss, true);
  const grid = hkTime.toDate() < cobrandTerminationDate?.toDate() ? "col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" : "col-sm-12 col-md-6 col-lg-4 d-flex align-items-stretch";

  useEffect(() => {
    props.dispatch(saveSchemeType(""));
    props.dispatch(saveAuthenticationFlag(false));
  }, []);

  return (
    <div className="main-content mb-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {hkTime.toDate() > cobrandTerminationDate?.toDate() && 
            <div className="col-14" id="contact-section">
              <label>{t("empfLoginMsg.impNote1")}</label>
              <label>{t("empfLoginMsg.impNote2")}</label>
              <label>{t("empfLoginMsg.impNote3")}</label>
            </div>
            }
            <h1 className="login-type-title">{t("titles.loginType")}</h1>
            <div className="row">
              {<div className={grid}>
                <S800andORSO />
              </div>}
              {hkTime.toDate() < cobrandTerminationDate?.toDate() &&
                <div className={grid}>
                  <SmartSimple />
                </div>
              }
              {
              <div className={grid}>
                <ErAgentsOtherInd />
              </div>}
              <div className={grid}>
                <OtherORSO />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginComponent.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func
};

const LoginComponentExport = connect()(LoginComponent);
export default class Login extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div id="wrapper">
        <LoginHeader></LoginHeader>
        <LoginComponentExport></LoginComponentExport>
      </div>
    );
  }
}